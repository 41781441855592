import React, { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from '../../../utils/getInitials';
import { useEffect } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, updates, ...rest }) => {
  const classes = useStyles();
  const [updateData, setUpdateData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

const deleteUpdateData = async id => {
  console.log(id);
    await axios
      .delete(
        '/api/LatestUpdate/' + id
      )
      .then(res => {
        console.log('Record is deleted', res);
        toast.success("Record Deleted Successfully")
      })
      .catch(error => {
        console.log(error);
          toast.error("Something Went Wrong");
      });
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    
  }, [updates]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
     <ToastContainer></ToastContainer>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>File</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {updates
                .slice(page * limit, page * limit + limit)
                .map(update => {
                  return (
                    <TableRow hover key={update.UId}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <a href={update.image} target="_blank">
                            <Avatar className={classes.avatar} src={update.image}>
                              {update.image}
                            </Avatar>
                          </a>
                          <Typography color="textPrimary" variant="body1">
                            {update.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{update.description}</TableCell>
                      <TableCell>
                        <a  href={update.file} target="_blank" onClick={(e)=>e.target.value}>
                          Open File
                        </a>
                      </TableCell>
                      <TableCell>{update.createdDate}</TableCell>

                      <TableCell>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => deleteUpdateData(update.uId)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={updates.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  updates: PropTypes.array.isRequired
};

export default Results;
