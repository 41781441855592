import { Box, Card, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import Results from './Results';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  floatingBtn: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    float: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const ViewAuditReports = ({ className, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  const getAllAuditData = async () => {
    await axios
      .get('/api/AuditReport')
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(error => {
          toast.error("Something went Wrong");
      });
  };

  useEffect(() => {
    getAllAuditData();
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <div className={classes.root}>
        <Results audit={data} />
      </div>
    </Card>
  );
};

export default ViewAuditReports;
