import React from 'react';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import { useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddUpdate = ({ handleDrawerClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Update">
    <ToastContainer></ToastContainer>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >

        <Container maxWidth="sm">

          <Formik
            initialValues={{
              Title: '',
              Description: '',
              Image: File,
              File: File
            }}
            onSubmit={async (values, { resetForm }) => {
              await axios
              .post('/api/LatestUpdate', values)
                .then(res => {
                  console.log(res.data);
                  console.log(values);
                  toast.success("Record Added Successfully")
                  handleDrawerClose();
                  resetForm();
                })
                .catch(error => {
                  console.log(error);
                    toast.error("Something Went Wrong");
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              handleChange,
              isSubmitting,
              touched,
              setFieldValue,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Add new Update
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.Title && errors.Title)}
                  fullWidth
                  helperText={touched.Title && errors.Title}
                  label="Title"
                  margin="normal"
                  name="Title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Title}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.Description && errors.Description)}
                  fullWidth
                  helperText={touched.Description && errors.Description}
                  label="Description"
                  margin="normal"
                  name="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Description}
                  variant="outlined"
                />

                <Typography color="textPrimary" variant="h4">
                  Image:
                </Typography>
                <TextField
                  error={Boolean(touched.Image && errors.Image)}
                  fullWidth
                  helperText={touched.Image && errors.Image}
                  margin="normal"
                  name="Image"
                  onBlur={handleBlur}
                  onChange={e => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFieldValue('image', fileReader.result);
                        
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }}
                  type="file"
                  variant="outlined"
                />
                <Typography color="textPrimary" variant="h4">
                  File:
                </Typography>
                <TextField
                  error={Boolean(touched.File && errors.File)}
                  fullWidth
                  helperText={touched.File && errors.File}
                  margin="normal"
                  name="File"
                  onBlur={handleBlur}
                  onChange={e => {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        setFieldValue('file', fileReader.result);
                        
                      }
                    };
                    fileReader.readAsDataURL(e.target.files[0]);
                  }}
                  type="file"
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Add
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default AddUpdate;
