import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';

import { deepPurple } from '@material-ui/core/colors';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  }
}));

const LatestContacts = ({ className, ...rest }) => {
  const classes = useStyles();

  const [contacts, setContacts] = useState([]);

  const getAllContact = async() =>{
    await axios.get("/api/LatestContact")
    .then((res)=>{
      console.log(res.data);
      setContacts(res.data);
    })
    .catch((error)=>{
        toast.error("Something went Wrong");
    })
  }

  const getFormatedArray = arr => {
    let combinedData = 'data:text/csv;charset=utf-8, Name, Email, ContactNo, \r\n';

    arr.forEach((val, idx) => {
      let propertiesArray = [];
      propertiesArray.push(val.name);
      propertiesArray.push(val.email);
      propertiesArray.push(val.contactNo);
      combinedData += propertiesArray.join(',') + '\r\n';
    });

    return combinedData;
  };

  const handleCSVDownload = e => {
    var link = document.createElement('a');
    let data = getFormatedArray(contacts);
    var encodedUri = encodeURI(data);
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);

    link.click();
  };

  useEffect(()=>{
    getAllContact();
  },[])

  const DownloadCSV = () => (
    <Box>
      <FormControl className={classes.formControl}>
        <Button onClick={handleCSVDownload} color="primary" size="large" variant="contained">Export CSV</Button>
      </FormControl>
    </Box>
  );

  return (
      <Card className={clsx(classes.root, className)} {...rest}>
          <ToastContainer />
     <CardHeader
        subtitle={`${contacts.length} in total`}
        title="Latest contacts"
        action={<DownloadCSV />}
      />
      <Divider />
      <Box
        height="450px"
        display="flex"
        flexDirection="column"
        overflow="scroll"
      >
       <List>
          {contacts.map((contact, index) => (
            <ListItem divider={index < contact.length - 1} key={index}>
              <ListItemAvatar>
                <Avatar className={classes.purple}>{contact.name[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={contact.Name}
                secondary={`Phone No: ${contact.contactNo}`}
              />
              <ListItemText secondary={`Email: ${contact.email}`} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
    </Card>
  );
};

LatestContacts.propTypes = {
  className: PropTypes.string
};

export default LatestContacts;
