import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    makeStyles,
    TextField
  } from '@material-ui/core';
  import React from 'react';
  import { useState,useEffect } from 'react';
  import clsx from 'clsx';
  import axios from "axios";
  import { NotificationManager } from 'react-notifications';
    import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  
  const CounterValues = ({ className, ...rest }) => {
    const useStyles = makeStyles(theme => ({
      root: {
        height: '100%',
        padding:'20px'
      }
    }));

  const [counterValues, setCounterValues] = useState({
      totalStudent: 0,
      course: 0,
      teacher: 0
    });

     const classes = useStyles();

     const getCounterValues = async () =>{
      await axios.get("/api/Counter")
      .then((res)=>{
        let counterData = res.data;
        console.log(counterData[counterData.length-1])
        setCounterValues(counterData[counterData.length-1]);
        
      })
      .catch((error)=>{
          toast.error("Something went Wrong");
      })
     }

    const counterData = async() =>{
      const payload = {
        totalStudent : counterValues.totalStudent,
        course : counterValues.course,
        teacher : counterValues.teacher
      }
      await axios.post("/api/Counter",payload)
      .then((res)=>{
        console.log(res.data);
        toast.success("Record Added Successfully");
      })
      .catch((error)=>{
        console.log(error);
          toast.error("Something went Wrong");
      })
    }

    useEffect(()=>{
      getCounterValues();
    },[])

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
                <ToastContainer />
        <CardHeader title="Counter Values" />
        <Divider />
        <TextField
          fullWidth
          label="Total Students"
          margin="normal"
          name="totalStudent"
          onChange={e => {
            setCounterValues({ ...counterValues, totalStudent: e.target.value });
          }}
          value={counterValues.totalStudent}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Courses"
          margin="normal"
          name="course"
          onChange={e => {
            setCounterValues({ ...counterValues, course: e.target.value });
          }}
          value={counterValues.course}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Certified Teachers"
          margin="normal"
          name="teacher"
          onChange={e => {
            setCounterValues({ ...counterValues, teacher: e.target.value });
          }}
          value={counterValues.teacher}
          variant="outlined"
        />
        <Box my={2}>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={counterData}
          >
            Set
          </Button>
        </Box>
      </Card>
    );
  };
  
  export default CounterValues;
  